<template>
  <b-card>
    <action-btn
      :selected-number="selectedRows.length"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      :actions="['createBtn', 'editorBtn', 'deleteBtn', 'reFresh']"
      @update-item="onUpdate"
      @delete-item="$refs.confirmDelete.showModal()"
      @refresh-page="refreshPage"
    />
    <good-table
      ref="nhomPhieu"
      class="mt-1"
      :class="checkAllQuyen ? '' : 'fixed-column'"
      :columns="getColumns"
      :rows="danhSachNhomPhieu"
      :total="tongSoNhomPhieu"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      @selected-item="onSelectRow"
      @column-filter="filterdanhSachNhomPhieu"
      @update-item="onUpdate"
      @delete-item="setItemUpdate"
      @page-change="pageChange"
    >
      <template
        slot="custom-filter"
        slot-scope="props"
      >
        <treeselect
          v-if="props.props.column.field === 'status'"
          v-model="filter.status"
          v-format-v-select
          class="vtreeselect-chooser"
          :options="optionsTrangThai"
          :limit="0"
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          placeholder="Chọn loại trạng thái"
          :clear-on-select="true"
          :before-clear-all="clearTreeSelect"
          @select="nextTickTreeSelect()"
        />
      </template>
    </good-table>
    <common-modal
      ref="commonModal"
      :title="title"
      @handle-ok="onSave"
      @reset-form="resetForm"
      @handle-focus="handleFocusError"
    >
      <b-form>
        <b-form-group
          class="required"
          label="Mã nhóm phiếu"
          label-for="maNhomPhieu"
        >
          <validation-provider
            v-slot="{ errors }"
            key="maNhomPhieu"
            :rules="{ required: true }"
            name="maNhomPhieu"
          >
            <b-form-input
              id="maNhomPhieu"
              ref="maNhomPhieu"
              v-model="form.maNhomPhieu"
              :autofocus="form.id ? false : true"
              type="text"
              :class="{required: errors.length > 0}"
              placeholder="Nhập mã nhóm phiếu"
            />
            <span class="text-danger">{{ errors[0] }}</span>
            <span class="text-danger">{{ errorMessage }}</span>
          </validation-provider>
        </b-form-group>
        <b-form-group
          class="required"
          label="Tên nhóm phiếu"
          label-for="tenNhomPhieu"
        >
          <validation-provider
            v-slot="{ errors }"
            key="tenNhomPhieu"
            :rules="{ required: true }"
            name="tenNhomPhieu"
          >
            <b-form-input
              id="tenNhomPhieu"
              ref="tenNhomPhieu"
              v-model="form.tenNhomPhieu"
              type="text"
              :class="{required: errors.length > 0}"
              placeholder="Nhập tên nhóm phiếu"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Ghi chú"
          label-for="ghiChu"
        >
          <b-form-textarea
            id="ghiChu"
            v-model="form.ghiChu"
            placeholder="Nhập ghi chú"
            rows="3"
            max-rows="6"
          />
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Trạng thái"
        >
          <b-form-checkbox
            v-model="form.status"
            switch
          />
        </b-form-group>
      </b-form>
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.hideModal(),$refs.commonnModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="confirmDelete"
      title="Xác nhận"
      size="sm"
      @handle-ok="onDelete"
    />
  </b-card>
</template>
<script>
import {
  BCard,
  BFormInput,
  BButton,
  BFormGroup,
  BForm,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/danh-muc/components/common/ActionBtn.vue'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import GoodTable from '@/components/GoodTable.vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import END_POINTS from '@/api/endpoints'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
// eslint-disable-next-line import/named
import _cloneDeep from 'lodash/cloneDeep'
import { compareObjects } from '@/utils'
import { STATUS } from '../../../../constants/constants'
import { checDeleteMultiple } from '../../../../utils/common-utils'

export default {
  components: {
    ActionBtn,
    GoodTable,
    BButton,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    CommonModal,
    ValidationProvider,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      required,
      isShow: false,
      isShowFooter: false,
      optionsTrangThai: STATUS,
      title: 'Thêm mới thông tin nhóm phiếu',
      isCreate: true,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Mã nhóm mẫu phiếu',
          field: 'maNhomPhieu',
          width: '200px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Nhóm mẫu phiếu',
          field: 'tenNhomPhieu',
          width: 'auto',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          field: 'status',
          width: '180px',
          thClass: 'text-center',
          tdClass: 'text-left',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Thao tác',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          field: 'actions',
          width: '100px',
        },
      ],
      filter: {
        maNhomPhieu: '',
        tenNhomPhieu: '',
        ghiChu: '',
        status: null,
        pageNumber: 1,
        pageSize: 10,
      },
      form: {
        maNhomPhieu: '',
        tenNhomPhieu: '',
        ghiChu: '',
        status: true,
      },
      beginObject: {},
      selectedRows: [],
      danhSachNhomPhieu: [],
      tongSoNhomPhieu: 0,
      errorMessage: '',
    }
  },
  computed: {
    checkQuyenThemMoi() {
      return hasPermission([PERMISSION_NAME.NHOM_PHIEU.THEM_MOI])
    },
    checkQuyenSua() {
      return hasPermission([PERMISSION_NAME.NHOM_PHIEU.SUA])
    },
    checkQuyenXoa() {
      return hasPermission([PERMISSION_NAME.NHOM_PHIEU.XOA])
    },
    checkAllQuyen() {
      return [this.checkQuyenSua, this.checkQuyenXoa].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    this.filterdanhSachNhomPhieu()
  },
  methods: {
    openModal() {
      if (compareObjects(this.beginObject, this.form)) {
        this.$refs.commonModal.hideModal()
        this.$refs.commonnModal.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    pageChange() {
      const data = this.$refs.nhomPhieu.getCurrentPage()
      const payload = {
        ...this.filter,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.NHOM_PHIEU.DANH_SACH,
        payload,
        false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachNhomPhieu = response.data.data
          this.tongSoNhomPhieu = response.data.totalCount || 0
        }
      })
    },
    filterdanhSachNhomPhieu(param = {}) {
      this.filter = {
        ...this.filter,
        ...param,
      }
      let payload = {
        ...this.filter,
      }
      if (this.$refs.nhomPhieu) {
        payload = {
          ...payload,
          ...this.$refs.nhomPhieu.getCurrentPage(),
        }
      }
      if (param.maNhomPhieu) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          maNhomPhieu: param.maNhomPhieu ? param.maNhomPhieu.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (param.tenNhomPhieu) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          tenNhomPhieu: param.tenNhomPhieu ? param.tenNhomPhieu.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (this.filter.status || !this.filter.status) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      this.$axios.post(END_POINTS.NHOM_PHIEU.DANH_SACH, this.$trimObject({
        ...payload,
      }), false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachNhomPhieu = response.data.data
          this.tongSoNhomPhieu = response.data.totalCount || 0
        }
      })
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.filterdanhSachNhomPhieu()
      })
    },
    refreshPage() {
      this.filter = {
        maNhomPhieu: '',
        tenNhomPhieu: '',
        ghiChu: '',
        status: null,
        pageNumber: 1,
        pageSize: 10,
      }
      this.$refs.nhomPhieu.$refs.vbTables.reset()
      this.filterdanhSachNhomPhieu()
    },
    addCommom() {
      this.isShow = true
      this.title = 'Thêm mới thông tin nhóm phiếu'
      this.isCreate = true
      this.$refs.commonModal.showModal()
    },
    onSelectRow(rows) {
      this.selectedRows = rows
    },
    onSave() {
      if (this.isCreate) {
        this.$axios.post(END_POINTS.NHOM_PHIEU.THEM_MOI, this.$trimObject(this.form), false).then(res => {
          if (res.data?.succeeded) {
            this.filterdanhSachNhomPhieu()
            this.$refs.commonModal.hideModal()
            this.$toast.success(res.data.message)
            this.errorMessage = ''
          } else {
            this.errorMessage = res.data.message
          }
        })
      } else {
        this.$axios.put(END_POINTS.NHOM_PHIEU.SUA, this.$trimObject(this.form), false).then(res => {
          if (res.data?.succeeded) {
            this.filterdanhSachNhomPhieu()
            this.$refs.commonModal.hideModal()
            this.$toast.success(res.data.message)
            this.errorMessage = ''
          } else {
            this.errorMessage = res.data.message
          }
        })
      }
    },
    onUpdate(item) {
      this.isShow = true
      this.form = {
        id: item.id,
        maNhomPhieu: item.maNhomPhieu,
        tenNhomPhieu: item.tenNhomPhieu,
        ghiChu: item.ghiChu,
        status: item.status,
      }
      this.title = 'Cập nhật thông tin nhóm phiếu'
      this.isCreate = false
      this.$nextTick(() => {
        this.beginObject = _cloneDeep(this.form)
        this.$refs.commonModal.showModal()
      })
    },
    onDelete() {
      this.isShow = false
      const payload = {
        listId: this.selectedRows.map(row => row.id),
      }
      this.$axios.delete(END_POINTS.NHOM_PHIEU.XOA, payload, false).then(res => {
        if (res.data?.succeeded) {
          if (this.danhSachNhomPhieu.length === 1) {
            const currentPage = this.$refs.nhomPhieu.getCurrentPage()
            const index = currentPage.pageNumber - 1
            this.$refs.nhomPhieu.resetCurrentPage(index === 0 ? 1 : index)
          }
          this.filterdanhSachNhomPhieu()
          checDeleteMultiple(this, res.data)
        }
      })
    },
    setItemUpdate() {
      // this.selectedRows = [item]
      this.$refs.confirmDelete.showModal()
    },
    resetForm() {
      this.form = {
        maNhomPhieu: '',
        tenNhomPhieu: '',
        ghiChu: '',
        status: true,
      }
      this.errorMessage = ''
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['loaiTrangThai'].includes(field)) {
          this.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs[field].focus()
        }
      }
    },
  },
}
</script>
